import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './minutes.scss';

const MinutesTemplate = ({data}) => {

  const pagedata = data.markdownRemark;
  const {title, subhead, date, participants, tags} = pagedata.frontmatter;

  return (
    <Layout>
      <SEO title={title} />
      <article>
        <h1 className="minutes--title">{title}</h1>
        { subhead && <h2 className="minutes--subhead">{ subhead }</h2>}
        <strong className="minutes--dateline">{date}</strong>
        <hr />
        <ul className="minutes--roll">
          <li className="label">Roll Call:</li>
          {
            participants.map((name) => (
              <li>{name}</li>
            ))
          }
          { participants.length >= 10 &&
            <li className="quorum">Quorum Achieved</li>
          }
        </ul>
        <hr />
        <section
          className="minutes--content"
          dangerouslySetInnerHTML={{__html: pagedata.html}}
        />
      </article>
{ /*
      <section className="minutes--tags">
        <h3>Tags:</h3>
        <ul>
          {
            tags.map((tag, i)=> <li key={i}>{tag}</li>)
          }
        </ul>
      </section>
 */ }
    </Layout>
  );
};

export const query = graphql `query($slug: String!) {
    markdownRemark( fields: { slug: {eq: $slug} } ) {
      frontmatter {
        title
        date(formatString: "dddd, MMMM D, YYYY") # Sunday, August 23, 2020
        participants
        tags
      }
      html
    }
  }
`;

export default MinutesTemplate;
